<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div
      class="d-flex justify-content-between align-items-center"
      slot="header"
    >
      <h5>{{ $route.meta.title }}</h5>
      <div class="d-flex justify-content-end align-items-center">
        <el-input
          placeholder="关键词搜索"
          size="small"
          v-model="params.keyword"
        >
        </el-input>
      </div>
    </div>
    <ul class="vlog-menu">
      <li :class="{ active: !params.type }" @click="switchType(0)">
        我关注({{ params.totalMyFollow }})
      </li>
      <li :class="{ active: params.type == 1 }" @click="switchType(1)">
        关注我({{ params.totalFollowMe }})
      </li>
    </ul>
    <div v-loading="loading">
      <el-table :data="lists">
        <el-table-column>
          <template slot-scope="scope">
            <NickName
              v-if="!params.type"
              v-model="scope.row.followUser"
            ></NickName>
            <NickName v-if="params.type" v-model="scope.row.user"></NickName>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="日期" width="180" sortable>
        </el-table-column>
        <el-table-column v-if="!params.type" label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-close"
              size="mini"
              @click="del(scope.row, scope.$index)"
              >取消关注</el-button
            >
          </template></el-table-column
        >
      </el-table>
    </div>

    <div class="text-center mt-20" v-if="params.pages > 1">
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="params.page"
        :page-size="params.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      loading: false,
      category: [],
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        type: 0,
        total: 0,
        totalMyFollow: 0,
        totalFollowMe: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.params.category_id = this.$route.meta.category_id;
    this.detailTag = this.$route.meta.detailTag;
    this.getList();
  },
  mounted() {},
  methods: {
    switchType(type) {
      this.params.type = type;
      this.getList();
    },
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.follow.Follow.lists(this.params)
        .then((res) => {
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.totalMyFollow = res.data.totalMyFollow;
          this.params.totalFollowMe = res.data.totalFollowMe;

          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },

    del(item, i) {
      console.log("enter into del item item.followUser.id:"+JSON.stringify(item.followUser.id));
      this.$utils.confirm(
        `确定要取关"${item.followUser.nickname}"吗？`,
        () => {
          this.$api.follow.Follow.del({ user_id: item.followUser.id })
            .then((res) => {
              this.$delete(this.lists, i);
              this.params.total--;
              if (this.lists.length < 1) {
                this.getList();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  vertical-align: top;
}
</style>